import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";

class AvitoPipelinesStore {
  pipelines: any[] = [];
  modalPipelineStatuses: any[] = [];
  pipelinesLoaded: boolean = false;
  isPipelinesModalOpened: boolean = false;
  selectedPipelineId: number | null = null;
  selectedPipelineStatusId: number | null = null
  selectedAvitoAccountId: number | null = null;
  
  constructor() {
    makeObservable(this, {
      pipelines: observable,
      pipelinesLoaded: observable,
      isPipelinesModalOpened: observable,
      selectedAvitoAccountId: observable,
      selectedPipelineId: observable,
      selectedPipelineStatusId: observable,
      modalPipelineStatuses: observable,

      setPipelines: action.bound,
      setPipelinesLoaded: action.bound,
      setIsPipelinesModalOpened: action.bound,
      setSelectedPipelineId: action.bound,
      setSelectedPipelineStatusId: action.bound,
      setSelectedAvitoAccountId: action.bound,
      setModalPipelineStatuses: action.bound,
    });
  }

  setModalPipelineStatuses() {
    if (!this.selectedPipelineId) return;
    let pipeline = this.pipelines.find((pipeline) => pipeline.id === this.selectedPipelineId);
    this.modalPipelineStatuses = pipeline.statuses;
  }

  setPipelines(pipelines: any[]) {
    this.pipelines = pipelines;
  }

  setPipelinesLoaded(b: boolean) {
    this.pipelinesLoaded = b;
  }

  setIsPipelinesModalOpened(b: boolean) {
    this.isPipelinesModalOpened = b;
  }

  setSelectedPipelineId = (id: number | null) => {
    this.selectedPipelineId = id;
    this.setModalPipelineStatuses();
  };

  setSelectedPipelineStatusId = (id: number | null) => {
    this.selectedPipelineStatusId = id;
  };
  setSelectedAvitoAccountId = (id: number | null) => {
    this.selectedAvitoAccountId = id;
  }
}

export const avitoPipelineStore = new AvitoPipelinesStore();
export const AvitoPipelineStoreContext = createContext(avitoPipelineStore);
export const useAvitoPipelineStore = () => useContext(AvitoPipelineStoreContext);
