import api from "../../app/api";

interface UpdateAvitoAccountPipelineStatusParams {
  avitoAccountId: number;
  pipelineId: number | null;
  statusId: number | null;
  token: string;
}

const updateAvitoAccountPipelineStatus = async ({
  avitoAccountId,
  pipelineId,
  statusId,
  token,
}: UpdateAvitoAccountPipelineStatusParams) => {
  try {
    const response = await api.put(
      `/avito/${avitoAccountId}/pipeline-status`, // Adjust the URL as needed
      {
        amoPipelineId: pipelineId,
        amoPipelineStatusId: statusId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`, // Adjust the header as needed
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating Avito account pipeline status:", error);
    return null;
  }
};

export default updateAvitoAccountPipelineStatus;
