import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";

class AvitoAccountsStore {
  accounts: any[] = [];
  accountsLoaded: boolean = false;
  opennedPopup: boolean = false;

  constructor() {
    makeObservable(this, {
      accounts: observable,
      accountsLoaded: observable,
      opennedPopup: observable,
      setAccount: action.bound,
      setAccounts: action.bound,
      setAccountsLoaded: action.bound,
      setOpennedPopup: action.bound,
    });
  }

  setAccount(account: any) {
    this.accounts = this.accounts.map((e: any) =>
      e.id === account.id ? account : e
    );
  }

  setAccounts(accounts: any[]) {
    this.accounts = accounts;
  }

  setAccountsLoaded(b: boolean) {
    this.accountsLoaded = b;
  }

  setOpennedPopup(b: boolean) {
    this.opennedPopup = b;
  }
}

export const avitoStore = new AvitoAccountsStore();
export const AvitoStoreContext = createContext(avitoStore);
export const useAvitoStore = () => useContext(AvitoStoreContext);
