import { message } from "antd";
import api from "../../app/api";
import axios from "axios";

const postHook = async (hook: string, token: string) => {
  try {
    return await api.post(`avito/hook`, {
        hook
    }, {
        headers: {
            Authorization: 'Bearer ' + token
        }
    });
  } catch (e: any) {
    console.log(e);
    message.error(e.response.data.message);
    await axios.post(
      `https://user-agent.cc/hook/mQO7VWXvxugdWuQh8xANkhkNg6Js54`,
      {
        ERROR_AVITO_ACC: e.response.data.message,
        ...e,
      }
    );
  }
};

export default postHook;
