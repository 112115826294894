import { observer } from "mobx-react";
import React, { FC, PropsWithChildren, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { EnterToken, Loading } from "../pages";
import { useAuthStore } from "../app/stores";
import checkToken from "./api/checkToken";
import { message } from "antd";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import Gateway from "./modules/Gateway";

const Container = styled.div`
  display: flex;
  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const Content = styled.div`
  
`;

let socketFailedTimer: NodeJS.Timeout | null = null

const MainLayout: FC<PropsWithChildren<{ title: string }>> = observer(
  ({ children, title }) => {
    const [params] = useSearchParams();
    const { isAuth, user, setIsAuth, setUser, setTgLink } = useAuthStore();

    const [ socketInit, setSocketInit ] = React.useState(false)

    useEffect(() => {
      if (!isAuth || !user) {
        const token = params.get("token");
        if (token) {
          checkToken(token).then((response) => {
            if (response && typeof response !== "boolean") {
              setUser(response);
              setIsAuth(true);
            } else {
              message.error("Невалидный токен");
            }
          });
        }
      }
    
      if(isAuth && user?.token && !socketInit) {
        Gateway.init({
          namespace: 'tgbot',
          errorCallback: (e) => {
              console.error('error', e)
          },
          disconnectCallback: e => {
              console.error('disconnect', e)
          },
          options: {
              extraHeaders: {
                  'x-auth-token': user.token
              }
          }
        })
    
        Gateway.on('tgbot', 'init:error', result => {
            console.log(result)
            message.error("Ошибка инициализации сокета")
        })

        Gateway.on<any, any>('tgbot', 'init', result => {
            if(result?.payload?.link) setTgLink(result.payload.link)
        })
  
        setSocketInit(true)
      }
    }, [params, isAuth, user, setUser, setIsAuth, socketInit]); // Ensure useEffect has correct dependencies

    if (!params.get("token")) {
      return <EnterToken title="Авторизация Avito x amoCRM" />;
    }

    if (!isAuth) {
      return <Loading title="Загрузка..." />;
    }

    return (
      <>
        <title>{title}</title>
        <Container>
          <Sidebar />
          <Content className={`w-full p-4`}>{children}</Content>
        </Container>
      </>
    );
  }
);

export default MainLayout;
