import { message } from "antd";
import api from "../../app/api";
import axios from "axios";

const uploadAvitoAccount = async (accountId: number) => {
  const body = {
    accountId
  };
  try {
    return await api.post(`avito/upload/day`, body);
  } catch (e: any) {
    return e
  }
};

export default uploadAvitoAccount;
