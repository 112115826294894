import { action, makeObservable, observable } from "mobx";
import { createContext, useContext } from "react";
import PaymentsEntity from "../../entities/payments";

class PaymentsStore {
  list: PaymentsEntity[] | null = null

  constructor() {
    makeObservable(this, {
        list: observable,
        setList: action.bound
    });
  }

  setList(list: PaymentsEntity[]) {
    this.list = list;
  }
}

export const paymentsStore = new PaymentsStore();
export const PaymentsStoreContext = createContext(paymentsStore);
export const usePaymentsStore = () => useContext(PaymentsStoreContext);
