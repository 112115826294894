import { observer } from "mobx-react";
import { Logs, LogsResponse } from "../types";
import { useEffect, useState } from "react";
import { Modal, Pagination, Table } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useAuthStore } from "../app/stores";
import { getLogs } from "../shared";
import Hook from "../types/hooks.interface";

const LogList = observer(() => {
  const [logs, setLogs] = useState<Hook[]>([]);
  const [totalLogs, setTotalLogs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedLog, setSelectedLog] = useState<Hook | null>(null);
  const { user } = useAuthStore();

  useEffect(() => {
    getLogs({ page: currentPage, pageSize, token: user.token }).then(
      (e: any) => {
        console.log(e.logs, e.totalLogs)
        setLogs(e.logs);
        setTotalLogs(e.totalLogs);
      }
    );
  }, [currentPage, pageSize, user.token]);

  const columns = [
    {
      title: "ID авито аккаунта",
      dataIndex: "client_number",
      key: "client_number",
      width: '180px',
      ellipsis: true,
    },
    {
      title: "Контент",
      dataIndex: "content",
      key: "content",
      ellipsis: true
    },
    {
      title: "Статус доставки",
      dataIndex: "delivery",
      key: "delivery",
      ellipsis: true,
      width: '170px',
      render: (value: boolean) => {
        if(value)return "Доставлен"
        return "Не доставлен"
      }
    },
    {
      title: "Статус обработки",
      dataIndex: "status",
      key: "status",
      width: '170px',
      ellipsis: true,
      render: (value: string) => {
        return value
      }
    },
    {
      title: "Тело хука",
      dataIndex: "body",
      key: "body",
      width: '140px',
      render: (value: any) => {
        return 'Тело'
      }
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      width: "10%",
      ellipsis: true,
      render: (value: Date) => {
        return new Date(value).toLocaleTimeString()
      }
    },
    {
      title: "Дата обновления",
      dataIndex: "updated_at",
      key: "updated_at",
      width: "10%",
      ellipsis: true,
      render: (value: Date) => {
        return new Date(value).toLocaleTimeString()
      }
    },
    {
      title: "Время обработки",
      dataIndex: "delay",
      key: "delay",
      ellipsis: true,
      width: '10%',
      render: (value: number) => {
        return value + ' мс.'
      }
    },
  ];

  const handlePageChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <Table
        dataSource={logs.map(l => {
          (l as any).delay = +new Date(l.updated_at) - +new Date(l.created_at)

          try {
            const hook: any = JSON.parse(l.hook)

            ;(l as any).client_number = hook?.payload?.value?.user_id
            ;(l as any).content = hook?.payload?.value?.content?.text
          }
          catch(e) {}

          return l
        })}
        columns={columns}
        rowKey="id"
        pagination={false}
        onRow={(record: Hook) => ({ onClick: () => setSelectedLog(record) })}
        scroll={{ x: "100%" }}
      />

      <Pagination
        style={{ marginTop: "20px" }}
        current={currentPage}
        pageSize={pageSize}
        total={totalLogs}
        onChange={handlePageChange}
        defaultPageSize={5}
      />

      <Modal
        title="Просмотр запроса"
        open={selectedLog !== null}
        onOk={() => setSelectedLog(null)}
        onCancel={() => setSelectedLog(null)}
        width={"1000px"}
      >
        {selectedLog ? (
          <>
            <p>ID: {selectedLog.id}</p>
            <p>ID авито аккаунта: {(() => {
              try {
                const hook: any = JSON.parse(selectedLog.hook)
                return hook?.payload?.value?.user_id
              }
              catch(e) {}
              return ''
            })()}</p>
            <p>Статус доставки: {selectedLog.delivery ? 'Доставлен' : 'Не доставлен'}</p>
            <p>Статус обработки: {selectedLog.status}</p>
            <p>Дата создания: {new Date(selectedLog.created_at).toLocaleString()}</p>
            <p>Дата обновления: {new Date(selectedLog.updated_at).toLocaleString()}</p>
            <p>Время обработки: {+new Date(selectedLog.updated_at) - +new Date(selectedLog.created_at)} миллисекунд</p>
            <p>Тело хука:</p>
            <pre className={`bg-gray-100 p-4 rounded-md`}>
              <code>{JSON.stringify({...JSON.parse(selectedLog.hook), payload: {
                ...JSON.parse(selectedLog.hook).payload,
                value: {
                  ...JSON.parse(selectedLog.hook).payload.value,
                  content: {
                    ...JSON.parse(selectedLog.hook).payload.value.content,
                    text: undefined
                  }
                }
              }}, null, 2)}</code>
            </pre>
            <p>Контент:</p>
            <TextArea value={JSON.parse(selectedLog.hook).payload.value.content.text} rows={4} />
          </>
        ) : (
          <>Ошибка: запись не выбрана</>
        )}
      </Modal>
    </>
  );
});

export default LogList;
